.button {
  height: 40px;
  width: 90px;
  background-color: #132233;
  border: 2px solid rgb(182, 128, 128);
  color: #eee;
  transition: .6s;
  font-size: 11px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 20px; */
  overflow: hidden;
}

.button span {
  transform: translateX(10px);
  transition: .5s;
}

.button svg {
  transform: translateX(-300px);
  transition: .6s;
  z-index: 3;
  height: 20px;
}

.button:hover {
  width: 60px;
  background-color: rgba(51, 34, 51, 0.815);
  box-shadow: 20px 20px 96px #bebebe,-20px -20px 96px #ffffff;
}

.button:hover svg {
  transform: translateX(-5px);
}

.button:hover span {
  transform: translateY(70px);
  font-size: .1rem;
}

.input-container {
  width: 220px;
  position: relative;
    margin-left: auto;
    margin-right: 120px;
}

.icon {
  position: absolute;
  right: 10px;
  top: calc(50% + 5px);
  transform: translateY(calc(-50% - 5px));
}

.input {
  width: 100%;
  height: 40px;
  padding: 10px;
  transition: .2s linear;
  border: 2.5px solid black;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.input:focus {
  outline: none;
  border: 0.5px solid black;
  box-shadow: -5px -5px 0px black;
}

.input-container:hover > .icon {
  animation: anim 1s linear infinite;
}

@keyframes anim {
  0%,
  100% {
    transform: translateY(calc(-50% - 5px)) scale(1);
  }

  50% {
    transform: translateY(calc(-50% - 5px)) scale(1.1);
  }
}